// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, {
    Dispatch, TransitionEvent, useEffect, useState,
} from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Layout from 'antd/lib/layout';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { CombinedState, DimensionType } from 'reducers/interfaces';
import LabelsList from 'components/annotation-page/standard-workspace/objects-side-bar/labels-list';
import { adjustContextImagePosition } from 'components/annotation-page/standard-workspace/context-image/context-image';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import AppearanceBlock from 'components/annotation-page/appearance-block';
import IssuesListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/issues-list';
import getCore from 'cvat-core-wrapper';

interface OwnProps {
    objectsList: JSX.Element;
}

interface StateToProps {
    sidebarCollapsed: boolean;
    canvasInstance: Canvas | Canvas3d;
    jobInstance: any;
    activeLabelID: number;
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
            drawing: { activeLabelID },
        },
    } = state;

    return {
        sidebarCollapsed,
        canvasInstance,
        jobInstance,
        activeLabelID,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        sidebarCollapsed, canvasInstance, collapseSidebar, objectsList, jobInstance, activeLabelID,
    } = props;

    const [targetLabelData, setTargetLabelData] = useState<{ target: number, labels:{ label_id: number, label_name: string, count: number }[] }>({
        target: 20,
        labels: [
            {
                label_id: 5,
                label_name: 'Лейбл номер один',
                count: 3,
            },
            {
                label_id: 6,
                label_name: 'Второй лейбл',
                count: 5,
            },
        ],
    });

    const collapse = (): void => {
        const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
        const listener = (event: TransitionEvent): void => {
            if (event.target && event.propertyName === 'width' && event.target === collapser) {
                canvasInstance.fitCanvas();
                canvasInstance.fit();
                (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
            }
        };

        if (collapser) {
            (collapser as HTMLElement).addEventListener('transitionend', listener as any);
        }

        adjustContextImagePosition(!sidebarCollapsed);
        collapseSidebar();
    };

    useEffect(() => {
        const cvat = getCore();
        const updateTargetStats = async () => {
            const targetsStats = await cvat.tasks.getTaskTargetStats(jobInstance.taskId);
            setTargetLabelData(targetsStats);
        };
        const updateIntervalTime = 600000;
        const timer = setInterval(updateTargetStats, updateIntervalTime);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const targetLabelRender = () => {
        const actualLabel = targetLabelData.labels.find((item) => item.label_id === activeLabelID);
        if (!actualLabel) {
            return (
                <div className='total_active_annotation_box'>
                    лейбла не найдено
                </div>
            );
        }
        const activeClass = targetLabelData.target <= actualLabel.count ? 'active' : '';
        return (
            <div className={`total_active_annotation_box ${activeClass}`}>
                {`${actualLabel.label_name} (${actualLabel.count}/${targetLabelData.target})`}
            </div>
        );
    };

    const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIM_2D : true;
    return (
        <Layout.Sider
            className='cvat-objects-sidebar'
            theme='light'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            {/* eslint-disable-next-line */}
            <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span>
            {targetLabelRender()}

            <Tabs type='card' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>Objects</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>Labels</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>

                {is2D ? (
                    <Tabs.TabPane tab={<Text strong>Issues</Text>} key='issues'>
                        <IssuesListComponent />
                    </Tabs.TabPane>
                ) : null}
            </Tabs>

            {!sidebarCollapsed && <AppearanceBlock />}
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
